// custom dependencies
import AOS from 'aos';
import svgInjector from 'svg-injector-2';
import slick from 'slick-carousel';

const deps = {
  AOS: AOS,
  svgInjector: svgInjector,
  slickCarousel: slick
};

export default deps;
